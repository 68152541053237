<template>
  <div class="personal_cont">
    <p class="title">{{ $t('common.field.infoDetails') }}</p>
    <el-row :gutter="16">
      <el-col :span="12" :xs="24">
        <div class="cont">
          <div class="top">
            <svg-icon iconClass="name"></svg-icon>
            <span>{{ $t('common.field.name') }}</span>
          </div>
          <div class="bottom" v-html="clientProfileData.name" v-if="clientProfileData.name"></div>
          <div class="bottom no_bind" v-else>{{ $t('common.field.noBind') }}</div>
        </div>
      </el-col>
      <el-col :span="12" :xs="24">
        <div class="cont">
          <div class="top">
            <svg-icon iconClass="country"></svg-icon>
            <span>{{ $t('common.field.country') }}</span>
          </div>
          <div class="bottom" v-html="clientProfileData.country" v-if="clientProfileData.country"></div>
          <div class="bottom no_bind" v-else>{{ $t('common.field.noBind') }}</div>
        </div>
      </el-col>
      <el-col :span="12" :xs="24">
        <div class="cont">
          <div class="top">
            <svg-icon iconClass="accType"></svg-icon>
            <span>{{ $t('common.field.accType') }}</span>
          </div>
          <div class="bottom" v-html="accountType[clientProfileData.accountType]" v-if="clientProfileData.accountType">
          </div>
          <div class="bottom no_bind" v-else>{{ $t('common.field.noBind') }}</div>
        </div>
      </el-col>
      <el-col :span="12" :xs="24">
        <div class="cont">
          <div class="top">
            <svg-icon iconClass="address"></svg-icon>
            <span>{{ $t('common.field.address') }}</span>
          </div>
          <div class="bottom" v-html="clientProfileData.streetAddress" v-if="clientProfileData.streetAddress"></div>
          <div class="bottom no_bind" v-else>{{ $t('common.field.noBind') }}</div>
        </div>
      </el-col>
      <el-col :span="12" :xs="24">
        <div class="cont">
          <div class="top">
            <svg-icon iconClass="email"></svg-icon>
            <span>{{ $t('common.field.emailAdd') }}</span>
          </div>
          <div class="bottom" v-html="clientProfileData.emailAddress" v-if="clientProfileData.emailAddress"></div>
          <div class="bottom no_bind" v-else>{{ $t('common.field.noBind') }}</div>
        </div>
      </el-col>
      <el-col :span="12" :xs="24">
        <div class="cont">
          <div class="top">
            <svg-icon iconClass="invest"></svg-icon>
            <span>{{ $t('common.field.save') }}</span>
          </div>
          <div class="bottom" v-html="clientProfileData.savingsInvestment" v-if="clientProfileData.savingsInvestment">
          </div>
          <div class="bottom no_bind" v-else>{{ $t('common.field.noBind') }}</div>
        </div>
      </el-col>
      <el-col :span="12" :xs="24">
        <div class="cont">
          <div class="top">
            <svg-icon iconClass="phone"></svg-icon>
            <span>{{ $t('common.field.phone') }}</span>
          </div>
          <div class="bottom" v-html="clientProfileData.phone" v-if="clientProfileData.phone"></div>
          <div class="bottom no_bind" v-else>{{ $t('common.field.noBind') }}</div>
        </div>
      </el-col>
      <el-col :span="12" :xs="24">
        <div class="cont">
          <div class="top">
            <svg-icon iconClass="income"></svg-icon>
            <span>{{ $t('common.field.annIncome') }}</span>
          </div>
          <div class="bottom" v-html="clientProfileData.averageIncome" v-if="clientProfileData.averageIncome"></div>
          <div class="bottom no_bind" v-else>{{ $t('common.field.noBind') }}</div>
        </div>
      </el-col>
      <el-col :span="12" :xs="24">
        <div class="cont">
          <div class="top">
            <svg-icon iconClass="earth"></svg-icon>
            <span>{{ $t('common.field.nat') }}</span>
          </div>
          <div class="bottom" v-html="clientProfileData.nationality" v-if="clientProfileData.nationality"></div>
          <div class="bottom no_bind" v-else>{{ $t('common.field.noBind') }}</div>
        </div>
      </el-col>
      <el-col :span="12" :xs="24">
        <div class="cont tip_cont">
          <div class="left">
            <div class="top">
              <svg-icon iconClass="bell"></svg-icon>
              <span>{{ $t('common.field.loginWarn') }}</span>
            </div>
            <div class="bottom">{{ $t('common.field.ipChangeWarning') }}</div>
          </div>
          <el-switch v-model="clientProfileData.ipChangeWarn" @change="changeIpWarningChange"></el-switch>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import myProfileMixin from '@/mixins/page/myProfile';
import { apiSubmitResetChangeIpWarn } from '@/resource';

export default {
  mixins: [myProfileMixin],
  data() {
    return {
      clientProfileData: {
        accountType: '',
        name: '',
        emailAddress: '',
        phone: '',
        dateBirth: '',
        nationality: '',
        country: '',
        streetAddress: '',
        suburb: '',
        state: '',
        postcode: '',
        employmentStatus: '',
        averageIncome: '',
        savingsInvestment: '',
        sourceOfFunds: '',
        ipChangeWarn: ''
      }
    };
  },
  methods: {
    changeIpWarningChange(value) {
      apiSubmitResetChangeIpWarn({ changeIpWarn: value }).then(resp => {
        if (resp.data.code == 0) {
          this.clientProfileData.ipChangeWarn = value;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
// @import '@/assets/css/pages/myProfile.scss';
.personal_cont {
  .title {
    font-size: 16px;
    color: #09090D;
    line-height: 24px;
    font-weight: 700;
  }

  @include screen-mobile {
    .el-col:nth-child(1) {
      .cont {
        margin-top: 24px;
      }
    }
  }

  .cont {
    padding: 16px;
    background: $background-color-base;
    border-radius: 8px;
    margin-top: 16px;

    .top {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .svg-icon {
        font-size: 24px;
        @include rtl-sass-prop(margin-right, margin-left, 8px);
      }

      span {
        font-size: 16px;
        color: $text-primary;
        line-height: 24px;
        font-weight: 700;
      }
    }

    .bottom {
      font-size: 14px;
      color: $text-secondary;
      line-height: 22px;
      @include rtl-sass-prop(margin-left, margin-right, 32px);

      &.no_bind {
        color: #ACACBF;
      }
    }

    &.tip_cont {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        margin-right: 4px;
      }
    }
  }
}
</style>
